<template>
  <div
    class="chat-item"
    :class="active ? 'active' : ''"
    @contextmenu.prevent="showRightMenu($event)"
  >
    <div class="chat-left">
      <head-image
        radius="50%"
        :url="chat.headImage"
        :name="chat.showName"
        :size="46"
        :id="chat.type == 'PRIVATE' ? chat.targetId : 0"
      ></head-image>
      <!-- 消息数 -->
      <div v-if="chat.unreadCount > 0" class="unread-text">
        <span v-if="chat.unreadCount < 100">{{ chat.unreadCount }}</span>
        <span v-else>99+</span>
      </div>
    </div>
    <div class="chat-right">
      <div class="chat-name">
        <div class="chat-tag" v-if="chat.type == 'GROUP'">
          <el-tag
            size="mini"
            style="
              padding: 0 8px;
              font-size: 11px;
              height: 18px;
              line-height: 18px;
            "
            >群</el-tag
          >
        </div>
        <div class="chat-name-text">{{ chat.showName }}</div>
        <div class="chat-time-text">{{ showTime }}</div>
      </div>
      <div class="chat-content">
        <div class="chat-at-text">{{ atText }}</div>
        <div class="chat-send-name" v-show="isShowSendName">
          {{ chat.sendNickName }}：
        </div>
        <div
          class="chat-content-text"
          v-html="$emo.transform(chat.lastContent)"
        ></div>
      </div>
    </div>
    <right-menu
      v-show="rightMenu.show"
      :pos="rightMenu.pos"
      :items="rightMenu.items"
      @close="rightMenu.show = false"
      @select="onSelectMenu"
    ></right-menu>
  </div>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";
import RightMenu from "../common/RightMenu.vue";

export default {
  name: "chatItem",
  components: {
    HeadImage,
    RightMenu,
  },
  data() {
    return {
      rightMenu: {
        show: false,
        pos: {
          x: 0,
          y: 0,
        },
        items: [
          {
            key: "TOP",
            name: "置顶",
            icon: "el-icon-top",
          },
          {
            key: "DELETE",
            name: "删除",
            icon: "el-icon-delete",
          },
        ],
      },
    };
  },
  props: {
    chat: {
      type: Object,
    },
    active: {
      type: Boolean,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    showRightMenu(e) {
      this.rightMenu.pos = {
        x: e.x,
        y: e.y,
      };
      this.rightMenu.show = "true";
    },
    onSelectMenu(item) {
      this.$emit(item.key.toLowerCase(), this.msgInfo);
    },
  },
  computed: {
    isShowSendName() {
      if (!this.chat.sendNickName) {
        return false;
      }
      let size = this.chat.messages.length;
      if (size == 0) {
        return false;
      }
      // 只有群聊的普通消息需要显示名称
      let lastMsg = this.chat.messages[size - 1];
      return this.$msgType.isNormal(lastMsg.type);
    },
    showTime() {
      return this.$date.toTimeText(this.chat.lastSendTime, true);
    },
    atText() {
      if (this.chat.atMe) {
        return "[有人@我]";
      } else if (this.chat.atAll) {
        return "[@全体成员]";
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
.chat-item {
  display: flex;
  position: relative;
  padding: 16px;
  border-radius: 2px;
  align-items: center;
  background-color: white;
  white-space: nowrap;
  margin-bottom: 6px;

  cursor: pointer;

  &:hover {
    background-color: #f8faff;
  }

  &.active {
    background-color: #f4f9ff;
  }

  .chat-left {
    position: relative;
    display: flex;
    width: 45px;
    height: 45x;

    .unread-text {
      position: absolute;
      top: -6px;
      left: 34px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background-color: #f56c6c;
      color: white;
      border-radius: 50%;
      font-size: 10px;
      text-align: center;
      white-space: nowrap;
    }
  }

  .chat-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    text-align: left;
    overflow: hidden;

    .chat-name {
      display: flex;
      line-height: 25px;
      height: 25px;

      .chat-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }

      .chat-name-text {
        flex: 1;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
      }

      .chat-time-text {
        white-space: nowrap !important;
        font-size: 12px;
        text-align: right;
        color: #95aac9;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .chat-content {
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-top: 2px;

      .chat-at-text {
        color: #c70b0b;
      }

      .chat-send-name {
        padding-left: 5px;
        color: #888;
      }

      .chat-content-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #888;

        img {
          width: 20px !important;
          height: 20px !important;
          vertical-align: bottom;
        }
      }
    }
  }
}
</style>